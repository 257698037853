<template>
  <div class="account-book-overview">
    <statistics class="mb-6" />

    <call-to-button :link="`/${accountBook.slug}/transactions/new`" icon="fa fa-plus" >
      {{ $t('transactions.newTitle') }}
    </call-to-button>
  </div>
</template>

<script>
import Statistics from '../Statistics'
import CallToButton from '../basic/CallToButton'
import { mapState } from 'vuex'

export default {
  name: 'account-book-overview',
  components: { CallToButton, Statistics },
  computed: {
    ...mapState({
      accountBook: state => state.accountBooks.current
    })
  }
}
</script>

<style scoped>

</style>
