<template>
  <div class="statistics">
    <subtitle class="is-flex is-justify-content-space-between">
      <span>{{ $t('statistics.title') }}</span>
      <button class="button is-small is-rounded" @click="showFilters = !showFilters">
        <span class="icon">
          <i class="fas fa-filter"></i>
        </span>
        <span>{{ $t('common.filter') }}</span>
      </button>
    </subtitle>

    <filters v-if="showFilters">
      <select-field
              :options="categoryOptions"
              :label="$t('transactions.list.filterByCategory')"
              name="categoryFilter"
              @change="filter"
              v-model="filters.categories"
              clearable
      />
    </filters>

    <div class="box month-statistics">
      <div class="is-flex is-justify-content-space-between statistics-box-navigation">
        <a @click.prevent="prevMonth">
        <span class="icon">
          <i class="fa fa-arrow-left"></i>
        </span>
          <span>{{ prevMonthStringFormatted }}</span>
        </a>

        <a @click.prevent="nextMonth" v-if="!isCurrentMonth">
          <span>{{ nextMonthStringFormatted }}</span>
          <span class="icon">
          <i class="fa fa-arrow-right"></i>
        </span>
        </a>

        <span class="placeholder" v-else></span>
      </div>

      <router-link :to="`/${accountBook.slug}/transactions`" class="box-link">
        <div class="card-content has-text-centered">
          <p class="heading">{{ thisMonthStringFormatted }}</p>
          <p class="title">{{ $n(currentMonth.total, 'currency') }}</p>
        </div>
        <footer class="is-flex-direction-column">
          <ul class="mt-3">
            <li class="is-flex is-justify-content-space-between" v-for="(userShare, key) in currentMonth.userShares" :key="key">
              <div>
                <span>{{ userShare.name.match(/\b(\w)/g).join('') }}</span>
                <span class="icon is-small">
                  <i class="fas fa-caret-right"></i>
                </span>
                <span>{{ currentMonth.userTotals[currentMonth.maxUser].name.match(/\b(\w)/g).join('') }}</span>
              </div>
              <div>
                {{ $n(userShare.missingShare, 'currency') }}
              </div>
            </li>
          </ul>
        </footer>
      </router-link>
    </div>

    <div class="box chart" v-if="accountBook.users.length > 1">
      <h2 class="subtitle has-text-weight-bold has-text-white">{{ $t('statistics.userTotalsTitle') }}</h2>
      <doughnut-chart :chart-data="userTotalsChartData" />
    </div>

    <div class="box chart">
      <h2 class="subtitle has-text-weight-bold has-text-white">{{ $t('statistics.amountByMonthTitle') }}</h2>
      <line-chart :chart-data="amountByMonth" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import LineChart from './basic/LineChart'
import { COLORS } from '../constants'
import moment from 'moment'
import Filters from './basic/Filters'
import SelectField from './basic/SelectField'
import Subtitle from './basic/Subtitle'
import DoughnutChart from './basic/DoughnutChart'
import { getColor } from '../helpers'

export default {
  name: 'statistics',
  components: { DoughnutChart, Subtitle, SelectField, Filters, LineChart },
  computed: {
    ...mapState({
      currentMonth: state => state.statistics.currentMonth,
      amountByMonth: state => {
        const data = []
        const labels = []

        for (const entry of state.statistics.amountByMonth) {
          labels.push(entry._id)
          data.push(entry.totalAmount)
        }

        return {
          labels: labels,
          datasets: [{
            borderColor: COLORS.standard.lighter,
            pointBackgroundColor: COLORS.standard.lighter,
            backgroundColor: null,
            data: data
          }]
        }
      },
      user: state => state.user,
      accountBook: state => state.accountBooks.current
    }),
    ...mapGetters([
      'categoryOptions'
    ]),
    nextMonthString () {
      return moment(this.currentMonth.month, 'MM-YYYY').add(1, 'months').format('MM-YYYY')
    },
    prevMonthString () {
      return moment(this.currentMonth.month, 'MM-YYYY').subtract(1, 'months').format('MM-YYYY')
    },
    nextMonthStringFormatted () {
      return moment(this.nextMonthString, 'MM-YYYY').format('MMM YYYY')
    },
    prevMonthStringFormatted () {
      return moment(this.prevMonthString, 'MM-YYYY').format('MMM YYYY')
    },
    thisMonthStringFormatted () {
      return moment(this.currentMonth.month, 'MM-YYYY').format('MMM YYYY')
    },
    isCurrentMonth () {
      return moment(this.currentMonth.month, 'MM-YYYY').format('MM-YYYY') === moment().format('MM-YYYY')
    },
    userTotalsChartData () {
      const userTotalsArray = Object.values(this.currentMonth.userTotals)

      return {
        labels: userTotalsArray.map(userTotal => userTotal.name),
        datasets: [{
          backgroundColor: userTotalsArray.map(userTotal => getColor(userTotal.color, 'lighter')),
          borderWidth: 1,
          data: userTotalsArray.map(userTotal => userTotal.total)
        }]
      }
    }
  },
  created () {
    this.$store.dispatch('categories/fetch')
    this.load()
  },
  data () {
    return {
      filters: {
        categories: null
      },
      showFilters: false
    }
  },
  methods: {
    filter () {
      this.load()
      this.showFilters = false
    },
    load () {
      this.$store.dispatch('statistics/getForMonth', {
        month: this.currentMonth.month,
        filters: this.filters
      })
      this.$store.dispatch('statistics/amountByMonth', {
        filters: this.filters
      })
    },
    isOverShare (userTotal) {
      return userTotal > this.currentMonth.total / Object.keys(this.currentMonth.userTotals).length
    },
    isBelowShare (userTotal) {
      return userTotal < this.currentMonth.total / Object.keys(this.currentMonth.userTotals).length
    },
    nextMonth () {
      this.$store.dispatch('statistics/getForMonth', {
        month: this.nextMonthString,
        filters: this.filters
      })
    },
    prevMonth () {
      this.$store.dispatch('statistics/getForMonth', {
        month: this.prevMonthString,
        filters: this.filters
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .statistics {
    .box.chart {
      background: $primary-dark;
    }

    .box-link {
      color: inherit;
    }

    .statistics-box-navigation {
      font-size: 0.8em;
      margin: -0.5rem -0.5rem auto;

      a, a:hover, a:active {
        color: $primary;
      }
    }

    .month-statistics {
      ul {
        li {
          font-size: 0.8em;
        }
      }

      ul {
        li {
          border-top: 0.1rem solid lighten($primary-light, 20);
          padding: 0.25rem 0;

          &:last-child {
            border-bottom: 0.1rem solid lighten($primary-light, 20);
          }
        }
      }
    }
  }
</style>
