import i18n from './i18n'
import { COLORS } from './constants'

export const formatNumber = (value) => {
  return i18n.n(value, 'currencyNoFractionDigits')
}

export const getColor = (color, type) => {
  let colorObject = COLORS[color]

  if (!colorObject) {
    colorObject = COLORS.standard
  }

  return colorObject[type]
}
