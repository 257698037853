<script>
import VueChartJS, { Line } from 'vue-chartjs'
import { COLORS } from '../../constants'
import { formatNumber } from '../../helpers'

export default {
  extends: Line,
  props: ['chartData'],
  mixins: [VueChartJS.mixins.reactiveProp],
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: true,
      legend: false,
      scales: {
        xAxes: [{
          ticks: {
            fontColor: 'white'
          },
          gridLines: {
            color: COLORS.standard.standard
          }
        }],
        yAxes: [{
          ticks: {
            fontColor: 'white',
            callback: formatNumber
          },
          gridLines: {
            color: COLORS.standard.standard
          }
        }]
      }
    }
  })
}
</script>

<style scoped>

</style>
