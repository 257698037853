<script>
import VueChartJS, { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['chartData'],
  mixins: [VueChartJS.mixins.reactiveProp],
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: true,
      legend: {
        align: 'start',
        position: 'bottom',
        labels: {
          fontColor: '#FFFFFF'
        }
      }
    }
  })
}
</script>

<style scoped>

</style>
